import { TradeOutletFormData } from "features/trade-outlet-form";
import { GetTradeOutletsItem } from "shared/api";

export const getUpdateFormInitialData = ({
  name,
  location,
  logoUrl,
}: GetTradeOutletsItem): TradeOutletFormData => ({
  name,
  location: location ?? "",
  logoUrl: logoUrl ?? undefined,
});
