import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";
import { format } from "shared/lib";
import { Box, Stack, Typography } from "shared/ui";

export const Tooltip = ({ active, payload }: TooltipProps<number, string>) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <Box sx={{ backgroundColor: "neutral.20", borderRadius: 0.5 }} p={1}>
        {payload.map((item) => (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            key={item.dataKey}
          >
            <Box
              width={8}
              height={8}
              sx={{ backgroundColor: item.stroke, borderRadius: "50%" }}
            />

            <Box>
              <Typography
                variant="smallTextSemiBold"
                color="common.white"
                display="inline"
              >
                {format(item.payload[item.dataKey!])}
              </Typography>{" "}
              <Typography
                variant="smallTextRegular"
                color="common.white"
                display="inline"
              >
                —{" "}
                {item.dataKey === "totalAmount"
                  ? t("overview.sales_chart.legend.gross")
                  : t("overview.sales_chart.legend.net")}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Box>
    );
  }

  return null;
};
