import { useTheme } from "@mychili/ui-web";
import {
  CartesianGrid,
  Line,
  LineChart as RechartsLineChart,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { OrdersChartPoint } from "shared/api";
import { Box } from "shared/ui";

import {
  BAR_WIDTH,
  CHART_CONTAINER_WIDTH,
  CHART_HEIGHT,
  MIN_CHART_WIDTH,
  TICK_INTERVAL,
} from "../constants";
import { Dot } from "./dot";
import { MainXAxisTick, TickProps, YAxisTick } from "./ticks";
import { Tooltip } from "./tooltip";

type LineChartProps = {
  data: OrdersChartPoint[];
  isSingleDataPoint: boolean;
  selectedTick: string | undefined;
  selectedView: "days" | "weeks" | "months";
  onTickClick: (tick?: string) => void;
};

export const OrdersSalesChart = ({
  data,
  isSingleDataPoint,
  selectedTick,
  selectedView,
  onTickClick,
}: LineChartProps) => {
  const { palette } = useTheme();

  const YearXAxisTick = (props: TickProps) => {
    const { x, y, index, payload, isSinglePoint } = props;

    const date = new Date(payload?.value!);
    const year = date.getFullYear();

    const prevItem = data[index! - 1];
    const prevDate = prevItem ? new Date(prevItem.intervalStartDate) : null;
    const prevItemYear = prevDate?.getFullYear();

    if (isSinglePoint && index !== 1) return null;

    if (index === 0 || (prevItemYear && prevItemYear !== year))
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fill={palette.neutral["70"]}
            cursor="pointer"
            fontSize={12}
            fontWeight={400}
          >
            {year}
          </text>
        </g>
      );

    return null;
  };

  return (
    <Box>
      <Box
        sx={{
          width: CHART_CONTAINER_WIDTH,
          overflowX: "auto",
          overflowY: "hidden",
          pb: "30px",
        }}
      >
        <RechartsLineChart
          width={Math.max(data.length * BAR_WIDTH, MIN_CHART_WIDTH)}
          height={CHART_HEIGHT}
          data={data}
          margin={{ right: 25 }}
        >
          <CartesianGrid stroke={palette.neutral[95]} strokeDasharray="3 3" />
          <Line
            dataKey="totalAmount"
            stroke={palette.primary["40"]}
            dot={
              <Dot
                fill={palette.primary["40"]}
                isSinglePoint={isSingleDataPoint}
              />
            }
          />
          <Line
            dataKey="totalSettlementAmount"
            stroke={palette.success["70"]}
            dot={
              <Dot
                fill={palette.success["70"]}
                isSinglePoint={isSingleDataPoint}
              />
            }
          />

          <YAxis
            tickCount={9}
            axisLine={false}
            tickLine={false}
            tick={<YAxisTick />}
          />
          <XAxis
            xAxisId={0}
            dataKey="intervalStartDate"
            tick={
              <MainXAxisTick
                selectedTick={selectedTick}
                onTickClick={onTickClick}
                selectedView={selectedView}
                isSinglePoint={isSingleDataPoint}
              />
            }
            axisLine={false}
            tickLine={false}
            interval={TICK_INTERVAL}
          />
          <XAxis
            xAxisId={1}
            dataKey="intervalStartDate"
            tick={<YearXAxisTick isSinglePoint={isSingleDataPoint} />}
            axisLine={false}
            tickLine={false}
            interval={0}
          />
          <RechartsTooltip content={<Tooltip />} />
        </RechartsLineChart>
      </Box>
    </Box>
  );
};
