import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_TRADE_OUTLETS } from "entities/trade-outlets";
import { updateTradeOutlet } from "shared/api";

export const useUpdateTradeOutlet = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateTradeOutlet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TRADE_OUTLETS] });
    },
  });
};
