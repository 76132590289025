import { Box } from "..";

type CalendarStylesWrapperProps = {
  children: React.ReactNode;
};

const sx = {
  ".react-calendar": {
    maxWidth: 568,
    border: "none",
    padding: "16px",
  },
  ".react-calendar__tile": {
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "common.white",
  },
  ".react-calendar__tile--now": {
    backgroundColor: "transparent",
  },
  ".react-calendar__tile--now abbr": {
    textDecoration: "underline",
  },
  ".react-calendar__month-view__days__day": {
    color: "neutral.20",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "3px 6px 5px 6px",
    marginBottom: "4px",
    border: "1px solid",
    borderColor: "common.white",
  },
  ".react-calendar__month-view__days__day:disabled": {
    backgroundColor: "inherit",
    color: "neutral.70",
  },
  ".react-calendar__month-view__days__day--weekend:disabled": {
    color: "error.80",
  },
  ".react-calendar__year-view__months": {
    height: "200px",
  },
  ".react-calendar__year-view__months__month": {
    padding: "3px 6px 5px 6px",
  },
  ".react-calendar__tile--now:enabled:hover": {
    backgroundColor: "neutral.90",
  },
  ".react-calendar__tile--now:enabled:focus": {
    backgroundColor: "neutral.90",
  },
  ".react-calendar__tile--hasActive:enabled:hover": {
    backgroundColor: "primary.50",
  },
  ".react-calendar__tile--hasActive:enabled:focus": {
    backgroundColor: "primary.50",
  },
  ".react-calendar__year-view__months .react-calendar__tile:enabled:hover": {
    borderRadius: "10px",
  },
  ".react-calendar__decade-view__years .react-calendar__tile:enabled:hover": {
    borderRadius: "10px",
  },
  ".react-calendar__month-view__days__day--weekend": {
    color: "primary.30",
  },
  ".react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: "none",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "21px",
  },
  ".react-calendar__tile--hover": {
    backgroundColor: "neutral.90",
  },
  ".react-calendar__tile--range": {
    backgroundColor: "neutral.95",
  },
  ".react-calendar__tile--rangeStart": {
    backgroundColor: "common.primary",
    color: "common.white",
  },
  ".react-calendar__tile--rangeEnd": {
    backgroundColor: "common.primary",
    color: "common.white",
  },
  ".react-calendar__tile--active:enabled:hover": {
    backgroundColor: "primary.50",
    color: "common.white",
  },
  ".react-calendar__tile--hasActive": {
    backgroundColor: "common.primary",
    color: "common.white",
    borderRadius: "10px",
  },
  ".react-calendar__tile--active:enabled:focus": {
    backgroundColor: "common.primary",
    color: "common.white",
  },
  ".react-calendar__navigation__label": {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 600,
    borderRadius: "10px",
  },
  ".react-calendar__navigation button:enabled:hover": {
    backgroundColor: "neutral.95",
  },
  ".react-calendar__navigation button:enabled:focus": {
    backgroundColor: "transparent",
  },
  ".react-calendar__navigation button:enabled:active": {
    backgroundColor: "neutral.90",
  },
  ".react-calendar__month-view__days__day--neighboringMonth": {
    visibility: "hidden",
  },
};

export const CalendarStylesWrapper = ({
  children,
}: CalendarStylesWrapperProps) => <Box sx={sx}>{children}</Box>;
