import { useBoolean } from "@mychili/ui-web";
import {
  useMerchant,
  useUpdateMerchant,
  useUploadMerchantLogo,
} from "entities/merchant";
import {
  MerchantFormDataWithLogo,
  UpdateMerchant,
} from "features/update-merchant";
import { useTranslation } from "react-i18next";
import {
  getS3ImageSrc,
  showSnackbarError,
  showSnackbarSuccess,
} from "shared/lib";
import {
  Box,
  Button,
  Card,
  InfoBlock,
  Loader,
  Logo,
  Stack,
  Typography,
} from "shared/ui";

export const MerchantInfo = () => {
  const { t } = useTranslation();

  const merchant = useMerchant();

  const uploadMerchantLogo = useUploadMerchantLogo();
  const updateMerchant = useUpdateMerchant();

  const editModal = useBoolean();

  const handleEditClick = () => {
    editModal.on();
  };

  const handleCopy = (title: string) => {
    showSnackbarSuccess(
      t("merchant_info.notification.field_copied", { field: title }),
    );
  };

  const handleUpdateSubmit = (data: MerchantFormDataWithLogo) => {
    if (data.logo) {
      uploadMerchantLogo.mutate(data.logo, {
        onError: () => {
          showSnackbarError(t("merchant_info.notification.upload_logo_error"));
        },
        onSuccess: ({ logo_url }) => {
          updateMerchant.mutate(
            {
              brand_name: data.brandName,
              logo_url,
            },
            {
              onError: () => {
                showSnackbarError(
                  t("merchant_info.notification.update_merchant_error"),
                );
              },
              onSuccess: () => {
                showSnackbarSuccess(t("common.notification.changes_saved"));

                editModal.off();
              },
            },
          );
        },
      });
    } else {
      updateMerchant.mutate(
        {
          brand_name: data.brandName,
          logo_url: data.logo === null ? null : data.logoUrl,
        },
        {
          onError: () => {
            showSnackbarError(
              t("merchant_info.notification.update_merchant_error"),
            );
          },
          onSuccess: () => {
            showSnackbarSuccess(t("common.notification.changes_saved"));

            editModal.off();
          },
        },
      );
    }
  };

  if (merchant.isLoading) return <Loader />;

  const logoSrc = getS3ImageSrc(merchant.data?.logoUrl);

  return (
    <>
      <Card sx={{ width: 500, marginX: "auto", p: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Logo src={logoSrc} size="s" />
          <Typography variant="bigTextSemiBold">
            {merchant.data?.name}
          </Typography>
        </Stack>

        <Stack spacing={1} mt={2.5}>
          <InfoBlock
            title={t("merchant_info.field.name")}
            description={merchant.data?.name ?? ""}
            canCopy
            onCopy={() => handleCopy(t("merchant_info.field.name"))}
          />
          <InfoBlock
            title={t("merchant_info.field.brand_name")}
            description={merchant.data?.brandName ?? ""}
            canCopy
            onCopy={() => handleCopy(t("merchant_info.field.brand_name"))}
          />
          <InfoBlock
            title={t("merchant_info.field.owner")}
            description={merchant.data?.owner ?? ""}
            canCopy
            onCopy={() => handleCopy(t("merchant_info.field.owner"))}
          />
          <InfoBlock
            title={t("merchant_info.field.id")}
            description={merchant.data?.id ?? ""}
            canCopy
            onCopy={() => handleCopy(t("merchant_info.field.id"))}
          />
          <InfoBlock
            title={t("merchant_info.field.address")}
            description={merchant.data?.address ?? ""}
            canCopy
            onCopy={() => handleCopy(t("merchant_info.field.address"))}
          />
          <InfoBlock
            title={t("merchant_info.field.registration_number")}
            description={merchant.data?.registrationNumber ?? ""}
            canCopy
            onCopy={() =>
              handleCopy(t("merchant_info.field.registration_number"))
            }
          />
        </Stack>

        <Box>
          <Button
            onClick={handleEditClick}
            variant="link"
            sx={{
              "&.MuiButtonBase-root": {
                paddingX: 0,
              },
            }}
          >
            {t("merchant_info.field.change_button_text")}
          </Button>
        </Box>
      </Card>

      <UpdateMerchant
        initialData={{
          brandName: merchant.data?.brandName ?? "",
          logoUrl: merchant.data?.logoUrl,
        }}
        isOpen={editModal.value}
        onClose={editModal.off}
        onSubmit={handleUpdateSubmit}
      />
    </>
  );
};
