import { t } from "i18next";
import { config } from "shared/lib";
import * as yup from "yup";

export const employeeSchema = yup.object().shape({
  name: yup
    .string()
    .required(t("common.validation.required"))
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      t("common.validation.latin_letters"),
    ),
  phone: yup
    .string()
    .required(t("common.validation.required"))
    .transform((value) => value.replace(/\s/g, ""))
    .length(
      config.country.phoneNumberLength,
      t("common.validation.phone_length", {
        length: config.country.phoneNumberLength,
        example: config.country.phoneExample,
      }),
    ),
  email: yup
    .string()
    .required(t("common.validation.required"))
    .email(t("common.validation.email")),
});
