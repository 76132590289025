import { OrdersChartPoint } from "shared/api";
import { formatDate } from "shared/lib";

type GetSalesChartDataReturnType = {
  salesChartData: OrdersChartPoint[];
  isSingleSalesChartDataPoint: boolean;
};

export const getSalesChartData = (
  data: OrdersChartPoint[] | undefined,
): GetSalesChartDataReturnType => {
  if (!data)
    return {
      salesChartData: [],
      isSingleSalesChartDataPoint: false,
    };

  const isSingleDataPoint = data.length === 1;

  let chartData = data;

  // Draw two additional points to draw a line if there is only one point
  if (isSingleDataPoint) {
    const date = new Date(data[0].intervalStartDate);
    const year = date.getFullYear();

    date.setFullYear(year - 1);
    const startDummyPoint: OrdersChartPoint = {
      ...data[0],
      intervalStartDate: formatDate(date),
    };

    date.setFullYear(year + 1);
    const endDummyPoint = {
      ...data[0],
      intervalStartDate: formatDate(date),
    };
    chartData = [startDummyPoint, ...chartData, endDummyPoint];
  }

  return {
    salesChartData: chartData,
    isSingleSalesChartDataPoint: isSingleDataPoint,
  };
};
