import { useState } from "react";
import { useTranslation } from "react-i18next";
import { countryCode } from "shared/config";
import { Box, Button, InputPhone } from "shared/ui";

import { getCodeErrorMessage, useGetCode } from "../../model";

export type EnterPhoneNumberViewProps = {
  error: unknown;
  onSubmit: (values: { phoneNumber: string }) => void;
};

export const EnterPhoneNumberView = ({
  error,
  onSubmit,
}: EnterPhoneNumberViewProps) => {
  const { t } = useTranslation();

  const getCode = useGetCode();

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInputPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.currentTarget.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({ phoneNumber });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <InputPhone
        autoFocus={true}
        countryCode={countryCode}
        error={Boolean(error)}
        helperText={getCodeErrorMessage(error)}
        inputProps={{ type: "tel" }}
        label={t("login.phone_input.label")}
        onChange={handleInputPhoneChange}
        sx={{ mb: 2 }}
      />
      <Button fullWidth={true} loading={getCode.isPending} type="submit">
        {t("login.submit_button_text")}
      </Button>
    </Box>
  );
};
