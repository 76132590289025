import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { GetOrdersCountByStatusesResponseData } from "shared/api";

export type OrderPublicStatus =
  | "CANCELLED"
  | "COMPLETED"
  | "DRAFT"
  | "REFUNDED"
  | "REFUND_IN_PROGRESS";

const PUBLIC_STATUS_BY_ORDER_STATUS: Record<
  bnplMerchants.OrderStatus,
  OrderPublicStatus
> = {
  ACTIVE: "COMPLETED",
  CANCELLED: "CANCELLED",
  CONFIRMED: "DRAFT",
  PAYMENT_FAILED: "DRAFT",
  PENDING: "DRAFT",
  PROCESSING: "DRAFT",
  REFUNDED: "REFUNDED",
  EXPIRED: "CANCELLED",
  REJECTED: "CANCELLED",
  REPAID: "COMPLETED",
  REFUND_IN_PROGRESS: "REFUND_IN_PROGRESS",
  REFUND_FAILED: "REFUND_IN_PROGRESS",
};

type ChartDataItem = {
  status: OrderPublicStatus;
  total: number;
};

export const getChartData = (
  data: GetOrdersCountByStatusesResponseData | undefined,
): ChartDataItem[] => {
  if (!data) return [];

  // @todo group on backend
  const groupedData = data.reduce(
    (result, item) => {
      const publicStatus = PUBLIC_STATUS_BY_ORDER_STATUS[item.status];

      return {
        ...result,
        [publicStatus]: result[publicStatus]
          ? result[publicStatus] + item.total
          : item.total,
      };
    },
    {
      COMPLETED: 0,
      REFUNDED: 0,
      REFUND_IN_PROGRESS: 0,
      CANCELLED: 0,
      DRAFT: 0,
    },
  );

  return (Object.keys(groupedData) as Array<keyof typeof groupedData>).map(
    (status) => ({
      status,
      total: groupedData[status],
    }),
  );
};
