import { useTranslation } from "react-i18next";
import { getCountdown, useTimer } from "shared/lib";
import { Button, Typography } from "shared/ui";

export const ResendCodeButton = ({
  countdown = 0,
  onResend,
}: {
  countdown?: number;
  onResend: () => void;
}) => {
  const { t } = useTranslation();

  const timer = useTimer(countdown);

  if (timer.state === "started") {
    return (
      <Typography variant="regularTextRegular">
        {t("login.confirm.countdown_label")} {getCountdown(timer.seconds)}
      </Typography>
    );
  }

  return (
    <Button onClick={onResend} size="medium" variant="text">
      {t("login.confirm.resend_button_text")}
    </Button>
  );
};
