import { OrdersChartPoint } from "shared/api";
import { formatDate } from "shared/lib";

import { LineChartViewType } from "../types";
import { getPeriodDisplayValue } from "./get-period-display-value";

export const getPointDetailsTitle = (
  data: OrdersChartPoint | undefined,
  chartView: LineChartViewType,
): string => {
  if (chartView === "days")
    return formatDate(data?.intervalStartDate, "D MMMM, YYYY");

  const startDate = new Date(data?.intervalStartDate!);
  const endDate = new Date(data?.intervalEndDate!);
  return getPeriodDisplayValue([startDate, endDate]);
};
