import { ColumnDef } from "@tanstack/react-table";
import { getOrdersPath } from "entities/orders";
import { useSettlementOrders } from "entities/settlement-orders";
import {
  formatSettlementNumber,
  useExportSettlement,
} from "entities/settlements";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetSettlementOrdersItem, GetSettlementsItem } from "shared/api";
import {
  analytics,
  format,
  formatDate,
  showSnackbarError,
  useTablePaginationState,
} from "shared/lib";
import {
  BaseLoader,
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  BaseTooltip,
  Box,
  IconArticle,
  IconButton,
  IconCalendar,
  IconCalendarCheck,
  IconDownload,
  IconPerson,
  IconToFill,
  InfoBlock,
  Loader,
  MenuItem,
  Popover,
  Stack,
  TableCellLinkButton,
  Typography,
} from "shared/ui";

export type SettlementDetailsProps = {
  settlement?: GetSettlementsItem;
};

export const SettlementDetails = ({ settlement }: SettlementDetailsProps) => {
  const { t } = useTranslation();

  const COLUMNS: ColumnDef<GetSettlementOrdersItem, any>[] = [
    {
      accessorKey: "orderNumber",
      header: t("settlements.detail.table.column.order_number"),
      cell: (cell) => {
        const orderNumber = cell.getValue();
        return (
          <TableCellLinkButton
            text={orderNumber}
            path={getOrdersPath({ search: orderNumber })}
          />
        );
      },
    },
    {
      accessorKey: "orderAmount",
      header: t("settlements.detail.table.column.order_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "commissionAmount",
      header: t("settlements.detail.table.column.comission_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "settlementAmount",
      header: t("settlements.detail.table.column.settlement_amount"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? format(value) : undefined;
      },
    },
    {
      accessorKey: "mdr",
      header: t("settlements.detail.table.column.mdr"),
      cell: (cell) => {
        const value = cell.getValue();
        return value ? `${value}%` : "";
      },
    },
  ];

  const pagination = useTablePaginationState();

  const exportSettlementToPdf = useExportSettlement({ fileFormat: "pdf" });
  const exportSettlementToXlsx = useExportSettlement({ fileFormat: "xlsx" });

  const orders = useSettlementOrders({
    settlementId: settlement?.id,
    params: {
      limit: pagination.currentLimit,
      offset: pagination.offset,
    },
  });

  const [downloadButtonAnchorEl, setDownloadButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handlePopoverClose = () => setDownloadButtonAnchorEl(null);

  if (!settlement) return null;

  const handleDownloadButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setDownloadButtonAnchorEl(e.currentTarget);
  };

  const handlePDFExport = () => {
    analytics.logAction("ClickPayoutExport", { format: "pdf" });

    exportSettlementToPdf.mutate(
      {
        settlementId: settlement.id!,
        params: { file_format: "pdf" },
      },
      {
        onError: () =>
          showSnackbarError(
            t("settlements.details.notification.pdf_export_error"),
          ),
      },
    );
  };

  const handleXLSXExport = () => {
    analytics.logAction("ClickPayoutExport", { format: "xlsx" });

    exportSettlementToXlsx.mutate(
      {
        settlementId: settlement.id!,
        params: { file_format: "xlsx" },
      },
      {
        onError: () =>
          showSnackbarError(
            t("settlements.details.notification.xlsx_export_error"),
          ),
      },
    );
  };

  const getRowId = (row: GetSettlementOrdersItem) => row.id!;

  if (orders.isLoading) return <Loader />;

  const payoutPeriod =
    settlement.periodFrom && settlement.periodTo
      ? `${formatDate(settlement.periodFrom, "DD/MM/YYYY")} - ${formatDate(settlement.periodTo, "DD/MM/YYYY")}`
      : settlement.periodTo
        ? formatDate(settlement.periodTo, "DD/MM/YYYY")
        : "-";

  return (
    <Stack sx={{ height: "100%" }} spacing={2.5}>
      <Stack
        px={2.5}
        py={1.25}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="neutral.95"
      >
        <Typography color="neutral.20" variant="headlineH4">
          {t("settlements.details.payout_id")}{" "}
          {formatSettlementNumber(settlement.settlementNumber)}
        </Typography>

        <BaseTooltip
          title={t("settlements.details.export_button.hint")}
          placement="bottom"
        >
          <div>
            <IconButton
              size="medium"
              color="gray"
              onClick={handleDownloadButtonClick}
            >
              <IconDownload />
            </IconButton>
          </div>
        </BaseTooltip>
      </Stack>

      <Box
        sx={{
          px: 2.5,
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <InfoBlock
              title={t("settlements.details.field.merchant_name")}
              description={settlement.merchantName}
              Icon={IconPerson}
            />
            <InfoBlock
              title={t("settlements.details.field.sales_amount")}
              description={String(settlement.salesAmount)}
              Icon={IconToFill}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <InfoBlock
              title={t("settlements.details.field.payment_date")}
              description={formatDate(settlement.paymentDate, "DD/MM/YYYY")}
              Icon={IconCalendarCheck}
            />
            <InfoBlock
              title={t("settlements.details.field.payout_period")}
              description={payoutPeriod}
              Icon={IconCalendar}
            />
            <InfoBlock
              title={t("settlements.details.field.created")}
              description={formatDate(settlement.created, "DD/MM/YYYY")}
              Icon={IconArticle}
            />
          </Stack>
        </Stack>
      </Box>

      <Box sx={{ flex: 1 }}>
        <BaseTableWrapper sx={{ height: 390 }}>
          <BaseTable
            data={orders.data?.items || []}
            isDataLoading={orders.isLoading}
            columns={COLUMNS}
            getRowId={getRowId}
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: orders.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>
      </Box>

      <Popover
        anchorEl={downloadButtonAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handlePopoverClose}
        open={Boolean(downloadButtonAnchorEl)}
      >
        <Stack p={0.5}>
          <Stack direction="row" alignItems="center">
            <MenuItem
              disabled={exportSettlementToPdf.isPending}
              onClick={handlePDFExport}
            >
              {t("settlements.details.export_pdf_button_text")}
            </MenuItem>
            {exportSettlementToPdf.isPending && <BaseLoader size={20} />}
          </Stack>
          <Stack direction="row" alignItems="center">
            <MenuItem
              disabled={exportSettlementToXlsx.isPending}
              onClick={handleXLSXExport}
            >
              {t("settlements.details.export_xlsx_button_text")}
            </MenuItem>
            {exportSettlementToXlsx.isPending && <BaseLoader size={20} />}
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};
