import { getEmployeeOutletsPayload } from "entities/employee-outlets";
import { handleEmployeeMutationError } from "entities/employees";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import {
  EmployeeForm,
  EmployeeFormDataForSubmit,
} from "features/employee-form";
import { useUpdateEmployeeOutlets } from "features/update-employee-outlets";
import { useTranslation } from "react-i18next";
import { EmployeeRole, GetEmployeesItem } from "shared/api";
import { parseLocalPhoneNumber, showSnackbarSuccess } from "shared/lib";
import { BaseDialog } from "shared/ui";

import { useUpdateEmployee } from "../model";

type UpdateEmployeeDialogProps = {
  employee: GetEmployeesItem | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const UpdateEmployeeDialog = ({
  employee,
  isOpen,
  onClose,
}: UpdateEmployeeDialogProps) => {
  const { t } = useTranslation();

  const updateEmployee = useUpdateEmployee();
  const updateEmployeeOutlets = useUpdateEmployeeOutlets();
  const tradeOutletsOptions = useTradeOutletsOptions();

  const handleSubmit = (formData: EmployeeFormDataForSubmit) => {
    const roles: Set<EmployeeRole> = new Set(employee?.roles!);

    if (formData.isRefundAllowed) {
      roles.add("refund_accessed_employee");
    } else {
      roles.delete("refund_accessed_employee");
    }

    updateEmployee.mutate(
      {
        employeeId: employee?.id!,
        payload: {
          name: formData.name,
          email: formData.email,
          phone: parseLocalPhoneNumber(formData.phone),
          roles: Array.from(roles),
        },
      },
      {
        onError: handleEmployeeMutationError,
        onSuccess: ({ id }) => {
          if (formData.tradeOutletsAccessIds) {
            const { bindIds, detachIds } = getEmployeeOutletsPayload(
              formData.tradeOutletsAccessIds,
            );
            updateEmployeeOutlets.mutate(
              {
                employeeId: id!,
                bindIds,
                detachIds,
              },
              {
                onSuccess: onClose,
              },
            );
          }

          showSnackbarSuccess(t("common.notification.changes_saved"));
        },
      },
    );
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("employees.edit_employee_modal.title")}
    >
      <EmployeeForm
        onClose={onClose}
        initialData={employee}
        tradeOutletsOptions={tradeOutletsOptions.data || []}
        onSubmit={handleSubmit}
      />
    </BaseDialog>
  );
};
