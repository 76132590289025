import { Box, Typography } from "shared/ui";

type EmployeeTradeOutletProps = {
  name: string | undefined;
  location: string | undefined;
};

export const EmployeeTradeOutlet = ({
  name,
  location,
}: EmployeeTradeOutletProps) => {
  return (
    <Box sx={{ backgroundColor: "common.white", borderRadius: 1 }} p={2}>
      <Typography variant="regularTextRegular">{name}</Typography>
      <Typography variant="smallTextRegular" color="neutral.50">
        {location}
      </Typography>
    </Box>
  );
};
