import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetMerchantResponseData = {
  address: bnplMerchants.MerchantExcludeIsBlocked["address"];
  archived: bnplMerchants.MerchantExcludeIsBlocked["archived"];
  created?: Date;
  id: bnplMerchants.MerchantExcludeIsBlocked["id"];
  logoUrl: bnplMerchants.MerchantExcludeIsBlocked["logo_url"];
  name: bnplMerchants.MerchantExcludeIsBlocked["name"];
  owner: bnplMerchants.MerchantExcludeIsBlocked["owner"];
  registrationNumber: bnplMerchants.MerchantExcludeIsBlocked["registration_number"];
  updated?: Date;
  website: bnplMerchants.MerchantExcludeIsBlocked["website"];
  brandName: bnplMerchants.MerchantExcludeIsBlocked["brand_name"];
};

export const getMerchant = async (): Promise<GetMerchantResponseData> => {
  const response = await bnplMerchants.getApiMerchantsServiceMerchant();

  if (!response.data.data) {
    throw new Error("Error getting merchant");
  }

  const {
    address,
    archived,
    created,
    id,
    logo_url,
    name,
    owner,
    registration_number,
    updated,
    website,
    brand_name,
  } = response.data.data;

  return {
    address,
    archived,
    created: created ? new Date(created) : undefined,
    id,
    logoUrl: logo_url,
    name,
    owner,
    registrationNumber: registration_number,
    updated: updated ? new Date(updated) : undefined,
    website,
    brandName: brand_name,
  };
};
