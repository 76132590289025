import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useQuery } from "@tanstack/react-query";
import { getOrdersCountByStatuses } from "shared/api";

import { QUERY_KEY_ORDERS_COUNT_BY_STATUSES } from "../../lib";

export const useOrdersCountByStatuses = (
  options: bnplMerchants.GetApiMerchantsServiceOrdersCountByStatusesParams,
) =>
  useQuery({
    queryFn: () => getOrdersCountByStatuses(options),
    queryKey: [QUERY_KEY_ORDERS_COUNT_BY_STATUSES, options],
  });
