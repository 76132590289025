import {
  TradeOutletForm,
  TradeOutletFormDataWithLogo,
} from "features/trade-outlet-form";
import { useUploadTradeOutletLogo } from "features/upload-trade-outlet-logo";
import { useTranslation } from "react-i18next";
import { GetTradeOutletsItem } from "shared/api";
import { showSnackbarError, showSnackbarSuccess } from "shared/lib";
import { BaseDialog } from "shared/ui";

import { getUpdateFormInitialData } from "../lib";
import { useUpdateTradeOutlet } from "../model";

type UpdateTradeOutletDialogProps = {
  tradeOutlet: GetTradeOutletsItem | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const UpdateTradeOutletDialog = ({
  tradeOutlet,
  isOpen,
  onClose,
}: UpdateTradeOutletDialogProps) => {
  const { t } = useTranslation();

  const uploadTradeOutletLogo = useUploadTradeOutletLogo();
  const updateTradeOutlet = useUpdateTradeOutlet();

  const handleSubmit = (data: TradeOutletFormDataWithLogo) => {
    if (!tradeOutlet?.id) return;

    if (data.logo) {
      uploadTradeOutletLogo.mutate(
        {
          tradeOutletId: tradeOutlet.id,
          imageBlob: data.logo,
        },
        {
          onError: () => {
            showSnackbarError(
              t("trade_outlets.notification.upload_logo_error"),
            );
          },
          onSuccess: ({ logo_url, id }) => {
            updateTradeOutlet.mutate(
              {
                tradeOutletId: id!,
                payload: {
                  name: data.name,
                  location: data.location || undefined,
                  logo_url: logo_url,
                },
              },
              {
                onError: () => {
                  showSnackbarError(
                    t("trade_outlets.notification.update_trade_outlet_error"),
                  );
                },
                onSuccess: () => {
                  showSnackbarSuccess(t("common.notification.changes_saved"));
                  onClose();
                },
              },
            );
          },
        },
      );
    } else {
      updateTradeOutlet.mutate(
        {
          tradeOutletId: tradeOutlet.id,
          payload: {
            name: data.name,
            location: data.location || undefined,
            logo_url: data.logo === null ? null : data.logoUrl,
          },
        },
        {
          onError: () => {
            showSnackbarError(
              t("trade_outlets.notification.update_trade_outlet_error"),
            );
          },
          onSuccess: () => {
            showSnackbarSuccess(t("common.notification.changes_saved"));
            onClose();
          },
        },
      );
    }
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("trade_outlets.edit_trade_outlet_modal.title")}
    >
      <TradeOutletForm
        onClose={onClose}
        initialData={
          tradeOutlet ? getUpdateFormInitialData(tradeOutlet) : undefined
        }
        onSubmit={handleSubmit}
      />
    </BaseDialog>
  );
};
