import { Tab, Tabs } from "@mychili/ui-web";

type BaseTabsItem = {
  label: string;
  value: string;
  disabled?: boolean;
};

type BaseTabsProps = {
  items: BaseTabsItem[];
  value: string;
  onChange: (event: React.SyntheticEvent, value: string) => void;
};

export const BaseTabs = ({ items, value, onChange }: BaseTabsProps) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      sx={{
        backgroundColor: "neutral.96",
        borderRadius: "8px",
        p: 0.5,
        "&.MuiTabs-root .MuiTabs-flexContainer": {
          gap: 0.5,
        },
        "&.MuiTabs-root .MuiTabs-indicator": {
          display: "none",
        },
      }}
    >
      {items.map((item) => (
        <Tab
          disableRipple
          label={item.label}
          value={item.value}
          disabled={item.disabled}
          key={item.value}
          sx={{
            px: 2,
            py: 1,
            border: "none",
            borderRadius: "6px",
            color: "neutral.50",
            textTransform: "none",
            lineHeight: "21px",
            "&.Mui-selected": {
              backgroundColor: "common.white",
              color: "neutral.20",
            },
            "&:not(.Mui-selected):hover": {
              backgroundColor: "neutral.90",
            },
            "&:disabled": {
              color: "neutral.80",
            },
          }}
        />
      ))}
    </Tabs>
  );
};
