import { useState } from "react";

import {
  IconChevronDown,
  IconChevronUp,
  Input,
  InputProps,
  OptionsDropdown,
  Popover,
} from "..";

type RadioSelectProps = {
  color: InputProps["color"];
  dropdownTitle?: string;
  onChange: any;
  value: string;
  placeholder?: string;
  sx?: InputProps["sx"];
  // @TODO Make id required after backend types update
  options?: { id?: string; name: string }[];
};

// @todo replace with select from ui-kit after redesign
export const RadioSelect = ({
  color,
  dropdownTitle,
  onChange,
  placeholder,
  sx,
  value,
  options = [],
}: RadioSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChange = (optionValue: string | string[]) => {
    onChange(optionValue);
    setAnchorEl(null);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const displayValue = options.find((option) => option.id === value)?.name;

  return (
    <>
      <Input
        color={color}
        variant="filled"
        onClick={handleInputClick}
        size="small"
        InputProps={{
          endAdornment: anchorEl ? (
            <IconChevronUp />
          ) : (
            <IconChevronDown sx={{ cursor: "pointer" }} />
          ),
          readOnly: true,
          sx: {
            // @TODO Remove after adding small inputs to DS
            ".MuiInputBase-input": {
              cursor: "pointer",
              py: 1.5,
              fontSize: 14,
              lineHeight: 21,
            },
          },
        }}
        placeholder={placeholder}
        value={displayValue}
        sx={sx}
      />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handlePopoverClose}
        open={Boolean(anchorEl)}
      >
        <OptionsDropdown
          type="radio"
          title={dropdownTitle}
          options={options}
          value={value}
          onChange={handleChange}
        />
      </Popover>
    </>
  );
};
