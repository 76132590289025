import { useEffect, useState } from "react";

import {
  IconButton,
  IconChevronDown,
  IconClose,
  Input,
  InputProps,
  Popover,
} from "..";
import { Calendar } from "./calendar";
import { getDisplayValue } from "./lib";
import { DatePickerValue } from "./types";

type DatePickerProps = {
  value: DatePickerValue;
  onChange: (value: DatePickerValue) => void;
  maxDate?: Date;
  onReset?: () => void;
  numberOfMonths?: number;
  placeholder?: InputProps["placeholder"];
  color?: InputProps["color"];
  showClearButton?: boolean;
  onCalendarOpen?: () => void;
};

const ClearButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <IconButton onClick={onClick}>
    <IconClose fontSize="small" />
  </IconButton>
);

export const DatePicker = ({
  color,
  onReset,
  value,
  onChange,
  placeholder,
  maxDate,
  showClearButton = false,
  onCalendarOpen,
}: DatePickerProps) => {
  const [inputValue, setInputValue] = useState<DatePickerValue>(value);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  const handleCloseCalendar = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    onCalendarOpen?.();
    setAnchorEl(e.currentTarget);
  };

  const handleClear = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    setInputValue(undefined);
    onChange(undefined);
  };

  const handleSubmit = (val: Date | Date[] | undefined) => {
    setInputValue(val);
    setAnchorEl(null);
    onChange(val);
  };

  const handleReset = () => {
    if (!onReset) return;

    onReset();
    handleCloseCalendar();
  };

  useEffect(() => {
    if (onReset) {
      setInputValue(value);
    }
  }, [onReset, value]);

  return (
    <>
      <Input
        autoComplete="off"
        color={color}
        onClick={handleClick}
        placeholder={placeholder}
        value={getDisplayValue(inputValue)}
        sx={{
          ".MuiInputBase-adornedEnd": {
            pl: 0,
            pr: 2,
          },
        }}
        fullWidth
        InputProps={{
          sx: {
            // @TODO Remove styles after adding small inputs to DS
            ".MuiInputBase-input": {
              py: 1.5,
              fontSize: 14,
              lineHeight: 21,
            },
          },
          endAdornment:
            inputValue && showClearButton ? (
              <ClearButton onClick={handleClear} />
            ) : (
              <IconChevronDown />
            ),
        }}
      />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(anchorEl)}
        onClose={handleCloseCalendar}
      >
        <Calendar
          onClose={handleCloseCalendar}
          onReset={onReset ? handleReset : undefined}
          onSubmit={handleSubmit}
          value={inputValue}
          maxDate={maxDate}
        />
      </Popover>
    </>
  );
};
