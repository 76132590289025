import { useEmployeeOutlets } from "entities/employee-outlets";
import { getOrdersPath } from "entities/orders";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GetEmployeesItem } from "shared/api";
import { analytics } from "shared/lib";
import {
  ActionButton,
  BaseSwitch,
  Box,
  Button,
  IconBox1,
  IconChevronRight,
  Loader,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import { EmployeeTradeOutlet } from "./employee-trade-outlet";

type EmployeeDetailsProps = {
  onDeleteClick: () => void;
  onEditClick: () => void;
  employee?: GetEmployeesItem;
  isLoading?: boolean;
};

export const EmployeeDetails = ({
  employee,
  onDeleteClick,
  onEditClick,
  isLoading,
}: EmployeeDetailsProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const employeeOutlets = useEmployeeOutlets({
    enabled: Boolean(employee?.id) && !isLoading,
    variables: {
      employee_id: employee?.id,
    },
  });

  if (!employee) return null;

  if (isLoading || employeeOutlets.isLoading) return <Loader />;

  const isRefundAllowed = employee.roles?.includes("refund_accessed_employee");

  const handleAllOrdersClick = () => {
    analytics.logAction("ClickEmployeeAllOrders");

    navigate(getOrdersPath({ employeeIdIn: employee.id }));
  };

  const employeeOutletsItems = employeeOutlets.data?.items || [];

  return (
    <Stack height="100%">
      <Typography p={2.5} color="neutral.20" variant="bigTextSemiBold">
        {employee.name}{" "}
        {employee.archived ? t("common.table.deleted_record_label") : ""}
      </Typography>

      <Box
        p={2.5}
        pb={5.25}
        sx={{
          borderTop: "1px solid",
          borderColor: "neutral.95",
          overflowY: "auto",
        }}
      >
        <Typography mb={2} variant="smallTextMedium">
          {t("employees.details.orders.label")}
        </Typography>

        <ActionButton
          onClick={handleAllOrdersClick}
          color="gray"
          text={t("employees.details.all_orders_button_text")}
          startIcon={<IconBox1 />}
          endIcon={<IconChevronRight />}
        />

        <Typography mt={2.5} mb={2} variant="smallTextMedium">
          {t("employees.details.stores_access.title")}
        </Typography>

        <Box mt={2}>
          <Stack direction="row" spacing={1.5}>
            <BaseSwitch disabled checked={isRefundAllowed} />
            <Typography variant="regularTextRegular" color="neutral.20">
              {t("employees.details.refund_access.label")}
            </Typography>
          </Stack>
        </Box>

        <Show when={employeeOutletsItems.length > 0}>
          <Stack
            spacing={1}
            mt={1.5}
            p={2}
            sx={{ backgroundColor: "neutral.96", borderRadius: 1 }}
          >
            {employeeOutletsItems.map(
              ({ id, tradeOutletName, tradeOutletLocation }) => (
                <EmployeeTradeOutlet
                  key={id}
                  name={tradeOutletName}
                  location={tradeOutletLocation}
                />
              ),
            )}
          </Stack>
        </Show>
      </Box>

      <Stack
        direction="row"
        spacing={1}
        p={2.5}
        width="100%"
        marginTop="auto"
        sx={{
          boxShadow:
            // @TODO Add to ui-web after appearing in DS
            "-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04)",
          borderTop: "1px solid",
          borderColor: "neutral.95",
        }}
      >
        {employee.archived ? (
          <Button disabled fullWidth>
            {t("employees.details.disabled_button_text")}
          </Button>
        ) : (
          <>
            <Button onClick={onDeleteClick} variant="secondary" fullWidth>
              {t("employees.details.delete_button_text")}
            </Button>
            <Button onClick={onEditClick} variant="primary" fullWidth>
              {t("employees.details.change_button_text")}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};
