import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type OrdersCountByStatusesItem =
  bnplMerchants.CountOrdersByStatusesRespMany;

export type GetOrdersCountByStatusesResponseData = OrdersCountByStatusesItem[];

export const getOrdersCountByStatuses = async (
  options: bnplMerchants.GetApiMerchantsServiceOrdersCountByStatusesParams,
): Promise<GetOrdersCountByStatusesResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceOrdersCountByStatuses(options);

  if (!response.data.data) {
    throw new Error("Error getting orders data");
  }

  return response.data.data;
};
