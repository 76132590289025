import { ActionEvent } from "./types";

export const ACTION_EVENTS = {
  // Common
  Login: {
    name: "login",
  },
  ClickSupport: {
    name: "click_support",
  },
  // Orders
  SearchOrder: {
    name: "search_order",
    properties: {
      search_string: "string",
    },
  },
  OpenOrderFilter: {
    name: "open_order_filter",
    properties: {
      field: "string",
    },
  },
  // Employees
  ClickCreateEmployee: {
    name: "click_create_employee",
  },
  ClickDeleteEmployee: {
    name: "click_delete_employee",
  },
  ClickUpdateEmployee: {
    name: "click_update_employee",
  },
  OpenEmployeeFilter: {
    name: "open_employee_filter",
    properties: {
      field: "string",
    },
  },
  SearchEmployee: {
    name: "search_employee",
    properties: {
      search_string: "string",
    },
  },
  ClickEmployeeAllOrders: {
    name: "click_employee_all_orders",
  },
  // Stores
  ClickCreateStore: {
    name: "click_create_store",
  },
  ClickDeleteStore: {
    name: "click_delete_store",
  },
  ClickUpdateStore: {
    name: "click_update_store",
  },
  OpenStoreFilter: {
    name: "open_store_filter",
    properties: {
      field: "string",
    },
  },
  SearchStore: {
    name: "search_store",
    properties: {
      search_string: "string",
    },
  },
  ClickStoreAllOrders: {
    name: "click_store_all_orders",
  },
  // Payout
  ClickPayoutUnpaidOrders: {
    name: "click_payout_unpaid_orders",
  },
  ClickPayoutDebit: {
    name: "click_payout_debit",
  },
  ClickPayoutExport: {
    name: "click_payout_export",
    properties: {
      format: "string",
    },
  },
} as const satisfies Record<string, ActionEvent>;
