import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { ColumnDef } from "@tanstack/react-table";
import { useEmployeesOptionsV2 } from "entities/employees";
import { useOrders, useOrdersStatuses } from "entities/orders";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import { useTranslation } from "react-i18next";
import { GetOrdersItem } from "shared/api";
import {
  analytics,
  format as formatCurrency,
  formatDate,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from "shared/lib";
import { useTableFilters } from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  FilterType,
  Label,
  LoadingOverlay,
  SearchInput,
  SortingOptions,
  Stack,
  Typography,
} from "shared/ui";

import { getStatusMappingData } from "../lib/get-status-mapping-data";

export const OrderList = () => {
  const { t } = useTranslation();

  const COLUMNS: ColumnDef<GetOrdersItem, any>[] = [
    {
      accessorKey: "created",
      header: t("orders.table.column.created"),
      cell: (cell) => {
        const value = cell.getValue();
        return value instanceof Date ? formatDate(value, "DD.MM.YYYY") : "";
      },
    },
    {
      accessorKey: "orderNumber",
      header: t("orders.table.column.order_number"),
    },
    {
      accessorKey: "status",
      header: t("orders.table.column.status"),
      cell: (cell) => {
        const { title, color } = getStatusMappingData(cell.getValue());
        return <Label color={color}>{title}</Label>;
      },
    },
    {
      accessorKey: "tradeOutletName",
      header: t("orders.table.column.trade_outlet_name"),
    },
    {
      accessorKey: "employeeName",
      header: t("orders.table.column.employee_name"),
    },
    {
      accessorKey: "amount",
      header: t("orders.table.column.amount"),
    },
  ];

  const SORTING_OPTIONS: SortingOptions<GetOrdersItem> = {
    created: [
      {
        name: t("common.table.sorting.date.desc"),
        id: "-created",
      },
      {
        name: t("common.table.sorting.date.asc"),
        id: "created",
      },
    ],
    orderNumber: [
      {
        name: t("common.table.sorting.date.desc"),
        id: "-order_number",
      },
      {
        name: t("common.table.sorting.date.asc"),
        id: "order_number",
      },
    ],
    status: [
      {
        name: t("common.table.sorting.string.asc"),
        id: "status",
      },
      {
        name: t("common.table.sorting.string.desc"),
        id: "-status",
      },
    ],
    tradeOutletName: [
      {
        name: t("common.table.sorting.string.asc"),
        id: "trade_outlet_name",
      },
      {
        name: t("common.table.sorting.string.desc"),
        id: "-trade_outlet_name",
      },
    ],
    employeeName: [
      {
        name: t("common.table.sorting.string.asc"),
        id: "employee_name",
      },
      {
        name: t("common.table.sorting.string.desc"),
        id: "-employee_name",
      },
    ],
    amount: [
      {
        name: t("common.table.sorting.number.asc"),
        id: "amount",
      },
      {
        name: t("common.table.sorting.number.desc"),
        id: "-amount",
      },
    ],
  };

  const pagination = useTablePagination();

  const { searchValue, handleSearchClear, handleSearchSubmit } =
    useTableSearch();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetOrdersItem>(SORTING_OPTIONS);

  const employeesOptions = useEmployeesOptionsV2();
  const tradeOutletsOptions = useTradeOutletsOptions();
  const ordersStatuses = useOrdersStatuses();

  const filtersConfig = [
    {
      field: "created",
      type: FilterType.Range,
      entityLabel: t("orders.table.filter.created.label"),
    },
    {
      field: "status",
      type: FilterType.Multiple,
      entityLabel: t("orders.table.filter.status.label"),
      options: ordersStatuses.data?.map((status) => ({
        id: status,
        name: status,
      })),
    },
    {
      field: "tradeOutletId",
      type: FilterType.Multiple,
      entityLabel: t("orders.table.filter.trade_outlet_id.label"),
      options: tradeOutletsOptions.data || [],
    },
    {
      field: "employeeId",
      type: FilterType.Multiple,
      entityLabel: t("orders.table.filter.employee_id.label"),
      options: employeesOptions.data || [],
    },
  ];

  const { filterState, handleFilterChange, handleFiltersReset } =
    useTableFilters<GetOrdersItem>(filtersConfig);

  const orders = useOrders({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    trade_outlet_id_in: filterState.tradeOutletId,
    employee_id_in: filterState.employeeId,
    status_in: filterState.status,
    created_ge:
      filterState.created?.length === 2
        ? filterState.created[0].toISOString()
        : undefined,
    created_le:
      filterState.created?.length === 2
        ? filterState.created[1].toISOString()
        : undefined,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceOrdersOrderBy,
    search_text: searchValue,
  });

  const totalAmount = Number(orders.data?.summary?.totalAmount);

  const getRowId = (row: GetOrdersItem) => row.id!;

  const handleSearchButtonClick = (value: string) => {
    analytics.logAction("SearchOrder", { search_string: value });
  };

  const handleFilterDropdownOpen = (field: string) => {
    analytics.logAction("OpenOrderFilter", { field });
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">{t("orders.title")}</Typography>

        <SearchInput
          onClear={handleSearchClear}
          onSubmit={handleSearchSubmit}
          defaultValue={searchValue}
          onButtonClick={handleSearchButtonClick}
        />
      </Stack>

      <BaseTableWrapper sx={{ height: 560 }}>
        <BaseTable
          columns={COLUMNS}
          data={orders.data?.items || []}
          sortingState={sortingState}
          onSortingChange={handleSortingChange}
          sortingOptions={SORTING_OPTIONS}
          filters={filtersConfig}
          filterState={filterState}
          onFilterChange={handleFilterChange}
          onFiltersReset={handleFiltersReset}
          isDataLoading={orders.isLoading}
          getRowId={getRowId}
          onFilterDropdownOpen={handleFilterDropdownOpen}
        />
        <BaseTablePagination
          bottomLabel={t("orders.total_amount", {
            amount: formatCurrency(totalAmount),
          })}
          state={{
            ...pagination,
            totalCount: orders.data?.pagination?.total,
          }}
        />
      </BaseTableWrapper>

      <LoadingOverlay open={orders.isLoading} />
    </>
  );
};
