import "react-calendar/dist/Calendar.css";

import {
  ActionButton,
  IconChevronLeft,
  IconChevronRight,
  Typography,
} from "@mychili/ui-web";
import { useState } from "react";
import { Calendar as ReactCalendar } from "react-calendar";
import { useTranslation } from "react-i18next";

import { Box, Button, Stack } from "..";
import { CalendarStylesWrapper } from "./calendar-styles-wrapper";
import { formatMonth, formatShortWeekday } from "./lib";
import {
  CalendarAction,
  CalendarValue,
  CalendarView,
  DatePickerValue,
} from "./types";

type CalendarProps = {
  value: DatePickerValue;
  onClose: () => void;
  onSubmit: (value: DatePickerValue) => void;
  maxDate?: Date;
  onReset?: () => void;
};

const renderNavigationLabel = ({ label }: { label: string }) => (
  <Typography variant="mediumTextSemiBold" display="inline">
    {label}
  </Typography>
);

export const Calendar = ({
  onSubmit,
  value,
  onClose,
  onReset,
  maxDate,
}: CalendarProps) => {
  const { t } = useTranslation();

  const [activeStartDateValue, setActiveStartDateValue] = useState<Date>(
    value && Array.isArray(value) ? value[0] : new Date(),
  );
  const [currentView, setCurrentView] = useState<CalendarView>("month");
  const [calendarValue, setCalendarValue] = useState<CalendarValue>(
    value as CalendarValue,
  );

  const handleChange = (v: CalendarValue) => {
    setCalendarValue(v);
  };

  const handleLastDaysClick = (days: number) => {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - days - 1);
    onSubmit([dateFrom, new Date()]);
  };

  const handleSubmit = () => {
    onSubmit(calendarValue as DatePickerValue);
  };

  const handleViewChange = ({ view }: { view: CalendarView }) => {
    setCurrentView(view);
  };

  const handleActiveStartDateChange = ({
    activeStartDate,
    action,
  }: {
    activeStartDate: Date | null;
    action: CalendarAction;
  }) => {
    if (action === "next" || action === "prev" || action === "drillDown") {
      setActiveStartDateValue(new Date(activeStartDate!));
    }
  };

  return (
    <Box>
      <Stack direction="row">
        <Stack
          p={1.5}
          sx={{ borderRight: "1px solid", borderColor: "neutral.95" }}
        >
          <ActionButton
            text={t("common.datepicker.prefilled_days_button.label", {
              days: 7,
            })}
            onClick={() => handleLastDaysClick(7)}
          />
          <ActionButton
            text={t("common.datepicker.prefilled_days_button.label", {
              days: 30,
            })}
            onClick={() => handleLastDaysClick(30)}
          />
          <ActionButton
            text={t("common.datepicker.prefilled_days_button.label", {
              days: 90,
            })}
            onClick={() => handleLastDaysClick(90)}
          />
        </Stack>

        <CalendarStylesWrapper>
          <ReactCalendar
            activeStartDate={activeStartDateValue}
            onActiveStartDateChange={handleActiveStartDateChange}
            onChange={handleChange}
            value={calendarValue}
            selectRange={currentView === "month"}
            prev2Label={null}
            next2Label={null}
            showDoubleView={currentView === "month"}
            onViewChange={handleViewChange}
            prevLabel={<IconChevronLeft />}
            nextLabel={<IconChevronRight />}
            view={currentView}
            navigationLabel={renderNavigationLabel}
            formatShortWeekday={formatShortWeekday}
            formatMonth={formatMonth}
            showNavigation={currentView !== "decade"}
            maxDate={maxDate}
          />
        </CalendarStylesWrapper>
      </Stack>
      <Stack
        sx={{ borderTop: "1px solid", borderColor: "neutral.95" }}
        direction="row"
        p={1.5}
        justifyContent={onReset ? "space-between" : "flex-end"}
      >
        {onReset && (
          <Button onClick={onReset} variant="link">
            {t("common.datepicker.reset_settings")}
          </Button>
        )}

        <Stack direction="row" spacing={1}>
          <Button variant="secondary" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <Button onClick={handleSubmit}>{t("common.ok")}</Button>
        </Stack>
      </Stack>
    </Box>
  );
};
