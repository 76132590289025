import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type OrdersChartPoint = {
  totalAmount: bnplMerchants.OverviewPeriodData["total_amount"];
  totalSettlementAmount: bnplMerchants.OverviewPeriodData["total_settlement_amount"];
  totalOrdersCount: bnplMerchants.OverviewPeriodData["total_orders_count"];
  avgAmount: bnplMerchants.OverviewPeriodData["avg_amount"];
  intervalStartDate: bnplMerchants.OverviewPeriodData["interval_start_date"];
  intervalEndDate: bnplMerchants.OverviewPeriodData["interval_end_date"];
};

export type OrdersChartResponseData = {
  days?: OrdersChartPoint[];
  weeks?: OrdersChartPoint[];
  months?: OrdersChartPoint[];
  avgAmount: bnplMerchants.GetOrdersOverviewChartResponse["avg_amount"];
  avgAmountDiff: bnplMerchants.GetOrdersOverviewChartResponse["avg_amount_diff"];
};

const mapPointData = (
  point: bnplMerchants.OverviewPeriodData,
): OrdersChartPoint => ({
  totalAmount: point.total_amount,
  totalSettlementAmount: point.total_settlement_amount,
  totalOrdersCount: point.total_orders_count,
  avgAmount: point.avg_amount,
  intervalStartDate: point.interval_start_date,
  intervalEndDate: point.interval_end_date,
});

export const getOrdersChartData = async (
  options: bnplMerchants.GetApiMerchantsServiceOrdersOverviewChartParams,
): Promise<OrdersChartResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceOrdersOverviewChart(options);

  if (!response.data.data) {
    throw new Error("Error getting chart data");
  }

  return {
    days: response.data.data.data_by_days?.map(mapPointData),
    weeks: response.data.data.data_by_weeks?.map(mapPointData),
    months: response.data.data.data_by_months?.map(mapPointData),
    avgAmount: response.data.data.avg_amount,
    avgAmountDiff: response.data.data.avg_amount_diff,
  };
};
