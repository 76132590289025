import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { parseInternationalPhoneNumber } from "shared/lib";

export type GetEmployeeResponseData =
  | {
      id?: bnplMerchants.EmployeeMany["id"];
      created?: bnplMerchants.EmployeeMany["created"];
      updated?: bnplMerchants.EmployeeMany["updated"];
      archived?: bnplMerchants.EmployeeMany["archived"];
      name: bnplMerchants.EmployeeMany["name"];
      role?: bnplMerchants.EmployeeMany["role"];
      phone: bnplMerchants.EmployeeMany["phone"];
      email: bnplMerchants.EmployeeMany["email"];
      roles?: bnplMerchants.EmployeeMany["roles"];
    }
  | undefined;

export const getEmployee = async (): Promise<GetEmployeeResponseData> => {
  const response = await bnplMerchants.getApiMerchantsServiceEmployee();

  if (!response.data.data) {
    throw new Error("Error getting employee");
  }

  return {
    ...response.data.data,
    phone: parseInternationalPhoneNumber(response.data.data.phone),
  };
};
