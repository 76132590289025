import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { config } from "shared/lib";
import { ROUTES } from "shared/routing";
import {
  Box,
  Button,
  IconInfo,
  IconOpenIn,
  Link,
  Stack,
  Typography,
} from "shared/ui";

export const PageWelcome = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleLoginButtonClick = () => {
    navigate(ROUTES.login);
  };

  return (
    <Stack
      maxWidth="md"
      mx="auto"
      justifyContent="center"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="img"
        display="block"
        mb={2}
        mx="auto"
        maxWidth={245}
        src="https://static.ahapay.my/logos/full.svg"
      />
      <Typography
        align="center"
        color="neutral.50"
        mb={5}
        variant="bigTextRegular"
      >
        {t("welcome.title")}
      </Typography>
      <Box mb={2}>
        <Button fullWidth={true} onClick={handleLoginButtonClick}>
          {t("welcome.login_button_text")}
        </Button>
      </Box>
      <Box maxWidth={250} mx="auto">
        <Stack direction="column">
          <Button
            color="neutral"
            component={Link}
            fullWidth={true}
            href={config.project.merchantsServiceUrl}
            size="medium"
            startIcon={<IconInfo />}
            sx={{
              justifyContent: "flex-start",
            }}
            // @ts-ignore TODO: fix props when provide component prop in ui-web
            target="_blank"
            variant="link"
          >
            {t("welcome.merchant_service_link_text")}
          </Button>
          <Button
            color="neutral"
            component={Link}
            fullWidth={true}
            href={config.project.clientAppUrl}
            size="medium"
            startIcon={<IconOpenIn />}
            sx={{
              justifyContent: "flex-start",
            }}
            // @ts-ignore TODO: fix props when provide component prop in ui-web
            target="_blank"
            variant="link"
          >
            {t("welcome.client_app_link_text")}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
