import { useTheme } from "@mychili/ui-web";
import { formatDate } from "shared/lib";

export type TickProps = {
  index?: number;
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
  selectedTick?: string;
  onTickClick?: (tick?: string) => void;
  selectedView?: string;
  isSinglePoint?: boolean;
};

export const YAxisTick = (props: TickProps) => {
  const { palette } = useTheme();

  const { y, payload } = props;
  return (
    <g transform={`translate(${0},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor="start"
        alignmentBaseline="central"
        fill={palette.neutral["50"]}
        fontSize="12px"
        fontWeight={400}
      >
        {Number(payload?.value).toLocaleString()}
      </text>
    </g>
  );
};

export const MainXAxisTick = (props: TickProps) => {
  const { palette } = useTheme();

  const {
    x,
    y,
    payload,
    selectedTick,
    onTickClick,
    selectedView,
    isSinglePoint,
    index,
  } = props;

  const handleClick = () => {
    onTickClick?.(payload?.value);
  };

  const isTickSelected = selectedTick === payload?.value;

  if (isSinglePoint && index !== 1) return null;

  return (
    <g transform={`translate(${x},${y})`} onClick={handleClick}>
      {isTickSelected ? (
        <rect
          x={-22}
          y={2}
          width="44"
          height="20"
          rx="4"
          ry="4"
          fill={palette.primary["40"]}
        />
      ) : null}

      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill={isTickSelected ? "white" : "black"}
        cursor="pointer"
        fontSize={12}
        fontWeight={400}
      >
        {formatDate(
          payload?.value,
          selectedView === "months" ? "MMM" : "DD MMM",
        )}
      </text>
    </g>
  );
};
