import packageJson from "../../package.json";

export const apiUrl = window.injectedEnv.REACT_APP_API_URL;
export const amplitudeApiKey = window.injectedEnv.REACT_APP_AMPLITUDE_API_KEY;
export const appVersion = packageJson.version;
export const countryCode = window.injectedEnv.REACT_APP_COUNTRY_CODE;
export const projectCode = window.injectedEnv.REACT_APP_PROJECT_CODE;
export const themeKey = window.injectedEnv.REACT_APP_THEME_KEY;
export const stand = window.injectedEnv.REACT_APP_STAND;
export const isProduction = stand === "production";
