import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_TRADE_OUTLETS } from "entities/trade-outlets";
import { uploadTradeOutletLogo } from "shared/api";

type UploadTradeOutletLogoParams = {
  tradeOutletId: string;
  imageBlob: Blob;
};

export const useUploadTradeOutletLogo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ tradeOutletId, imageBlob }: UploadTradeOutletLogoParams) =>
      uploadTradeOutletLogo(tradeOutletId, { logo: imageBlob }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TRADE_OUTLETS] });
    },
  });
};
