import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { parseInternationalPhoneNumber } from "shared/lib";

export type GetEmployeesItem = {
  id: bnplMerchants.EmployeeMany["id"];
  archived: bnplMerchants.EmployeeMany["archived"];
  created: Date | undefined;
  updated: Date | undefined;
  merchantId: bnplMerchants.EmployeeMany["merchant_id"];
  name: bnplMerchants.EmployeeMany["name"];
  role: bnplMerchants.EmployeeMany["role"];
  phone: bnplMerchants.EmployeeMany["phone"];
  email: bnplMerchants.EmployeeMany["email"];
  roles: bnplMerchants.EmployeeMany["roles"];
};

type GetEmployeesResponseData = {
  items: GetEmployeesItem[] | undefined;
  pagination: bnplMerchants.Pagination | undefined;
};

export const getEmployees = async (
  options: bnplMerchants.GetApiMerchantsServiceEmployeesParams,
): Promise<GetEmployeesResponseData> => {
  const response = await bnplMerchants.getApiMerchantsServiceEmployees(options);

  return {
    items: response.data.data?.items?.map((i) => ({
      id: i.id,
      archived: i.archived,
      created: i.created ? new Date(i.created) : undefined,
      updated: i.updated ? new Date(i.updated) : undefined,
      merchantId: i.merchant_id,
      name: i.name,
      role: i.role,
      phone: i.phone ? parseInternationalPhoneNumber(i.phone) : "",
      email: i.email,
      roles: i.roles,
    })),
    pagination: response.data.data?.pagination,
  };
};
