import { ProjectConfig } from "../../types";

const config: ProjectConfig = {
  minLogoWidth: 512,
  merchantSupportEmail: "merchant@ahapay.my",
  supportEmail: "support@ahapay.com",
  supportUrl: "https://merchant-help.ahapay.my/hc/en-my",
  merchantsServiceUrl: "https://ahapay.my/signup",
  clientAppUrl: "https://ahapay.my",
};

export default config;
