import { LayoutMain } from "widgets/layout-main";
import { OrdersOverview } from "widgets/orders-overview";

export const PageOverview = () => {
  return (
    <LayoutMain>
      <OrdersOverview />
    </LayoutMain>
  );
};
