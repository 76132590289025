import { useTranslation } from "react-i18next";
import { GetEmployeesItem } from "shared/api";
import { BaseDialog, Box, Button, Stack, Typography } from "shared/ui";

import { useDeleteEmployee } from "../model";

type DeleteEmployeeDialogProps = {
  employee: GetEmployeesItem | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteEmployeeDialog = ({
  employee,
  isOpen,
  onClose,
  onSuccess,
}: DeleteEmployeeDialogProps) => {
  const { t } = useTranslation();

  const deleteEmployee = useDeleteEmployee();

  const handleSubmit = () => {
    deleteEmployee.mutate(
      {
        employeeId: employee?.id!,
      },
      {
        onSuccess: () => {
          onSuccess();
          onClose();
        },
      },
    );
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("employees.delete_employee_modal.title")}
    >
      <Box p={2.5}>
        <Typography variant="regularTextRegular" color="neutral.50">
          {t("employees.delete_employee_modal.confirmation.text", {
            name: employee?.name,
          })}
        </Typography>
        <Typography mt={0.5} variant="regularTextRegular" color="neutral.50">
          {t("employees.delete_employee_modal.confirmation.warning")}
        </Typography>

        <Stack direction="row" spacing={1} mt={2}>
          <Button onClick={onClose} variant="secondary" fullWidth>
            {t("common.close")}
          </Button>
          <Button onClick={handleSubmit} fullWidth>
            {t("employees.delete_employee_modal.delete_button_text")}
          </Button>
        </Stack>
      </Box>
    </BaseDialog>
  );
};
