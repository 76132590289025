import { useTranslation } from "react-i18next";
import { OrdersChartPoint } from "shared/api";
import { format } from "shared/lib";
import { Card, Typography } from "shared/ui";

type PointDetailsProps = {
  title: string;
  data: OrdersChartPoint | undefined;
};

export const PointDetails = ({ title, data }: PointDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ p: 2.5, backgroundColor: "neutral.96", height: 372 }}>
      <Typography variant="regularTextSemiBold" mb={3.375}>
        {title}
      </Typography>

      <Typography variant="extraSmallTextRegular">
        {t("overview.sales_chart.point_details.total_amount")}
      </Typography>
      <Typography variant="smallTextSemiBold" mt={0.5}>
        {format(data?.totalAmount ?? 0)}
      </Typography>
      <Typography variant="extraSmallTextRegular" mt={1}>
        {t("overview.sales_chart.point_details.total_settlement_amount")}
      </Typography>
      <Typography variant="smallTextSemiBold" mt={0.5}>
        {format(data?.totalSettlementAmount ?? 0)}
      </Typography>
      <Typography variant="extraSmallTextRegular" mt={1}>
        {t("overview.sales_chart.point_details.avg_amount")}
      </Typography>
      <Typography variant="smallTextSemiBold" mt={0.5}>
        {format(data?.avgAmount ?? 0)}
      </Typography>
      <Typography variant="extraSmallTextRegular" mt={1}>
        {t("overview.sales_chart.point_details.total_orders_count")}
      </Typography>
      <Typography variant="smallTextSemiBold" mt={0.5}>
        {data?.totalOrdersCount}
      </Typography>
    </Card>
  );
};
