import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useBoolean } from "@mychili/ui-web";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useEmployees } from "entities/employees";
import { useEmployeesOptionsV2 } from "entities/employees";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import { CreateEmployeeDialog } from "features/create-employee";
import { DeleteEmployeeDialog } from "features/delete-employee";
import { EmployeeDetails } from "features/employee-details";
import { UpdateEmployeeDialog } from "features/update-employee";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetEmployeesItem } from "shared/api";
import { countryCode } from "shared/config";
import {
  analytics,
  showSnackbarSuccess,
  useTableFilters,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  Button,
  Card,
  FilterType,
  FormatPhone,
  IconAdd,
  LoadingOverlay,
  SearchInput,
  SortingOptions,
  Stack,
  Typography,
} from "shared/ui";

export const EmployeeList = () => {
  const { t } = useTranslation();

  const columns: ColumnDef<GetEmployeesItem, any>[] = [
    {
      header: t("employees.table.column.name"),
      accessorKey: "name",
    },
    {
      header: t("employees.table.column.phone"),
      accessorKey: "phone",
      cell: (cell) => (
        <FormatPhone countryCode={countryCode} value={cell.getValue()} />
      ),
    },
    {
      header: t("employees.table.column.email"),
      accessorKey: "email",
    },
    {
      accessorKey: "archived",
    },
  ];

  const sortingOptions: SortingOptions<GetEmployeesItem> = {
    name: [
      {
        name: t("common.table.sorting.string.asc"),
        id: "name",
      },
      {
        name: t("common.table.sorting.string.desc"),
        id: "-name",
      },
    ],
  };

  const createEmployeeDialog = useBoolean();
  const updateEmployeeDialog = useBoolean();
  const deleteEmployeeDialog = useBoolean();

  const [selectedRowId, setSelectedRowId] = useState<string>();

  const pagination = useTablePagination();

  const { searchValue, handleSearchClear, handleSearchSubmit } =
    useTableSearch();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetEmployeesItem>(sortingOptions);

  const employeesOptions = useEmployeesOptionsV2();
  const tradeOutletsOptions = useTradeOutletsOptions();

  const filtersConfig = [
    {
      field: "id",
      type: FilterType.Multiple,
      entityLabel: t("employees.table.filter.id.label"),
      options: employeesOptions.data,
    },
    {
      field: "tradeOutletId",
      type: FilterType.Multiple,
      entityLabel: t("employees.table.filter.trade_outlet_id.label"),
      options: tradeOutletsOptions.data,
    },
  ];

  const { filterState, handleFilterChange, handleFiltersReset } =
    useTableFilters<GetEmployeesItem & { tradeOutletId: string }>(
      filtersConfig,
    );

  const employees = useEmployees({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    id_in: filterState.id,
    trade_outlet_id_in: filterState.tradeOutletId,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceEmployeesOrderBy,
    search_text: searchValue,
  });

  const handleRowSelect = (row: Row<GetEmployeesItem>) => {
    setSelectedRowId(row.id);
  };

  const handleAddEmployeeClick = () => {
    createEmployeeDialog.on();
    analytics.logAction("ClickCreateEmployee");
  };

  const handleDeleteEmployeeClick = () => {
    deleteEmployeeDialog.on();
    analytics.logAction("ClickDeleteEmployee");
  };

  const handleEditEmployeeClick = () => {
    updateEmployeeDialog.on();
    analytics.logAction("ClickUpdateEmployee");
  };

  const handleSearchButtonClick = (value: string) => {
    analytics.logAction("SearchEmployee", { search_string: value });
  };

  const handleFilterDropdownOpen = (field: string) => {
    analytics.logAction("OpenEmployeeFilter", { field });
  };

  const handleDeleteEmployeeSuccess = () => {
    if (employees.data?.items?.[0]) {
      setSelectedRowId(employees.data?.items[0].id);
    }

    deleteEmployeeDialog.off();
    showSnackbarSuccess(t("employees.notification.employee_deleted"));
  };

  const handleCreateEmployeeClose = (data?: { employeeId?: string }) => {
    if (data?.employeeId) {
      setSelectedRowId(data.employeeId);
    }

    createEmployeeDialog.off();
  };

  useEffect(() => {
    if (
      selectedRowId === undefined &&
      employees.data?.items?.length &&
      employees.data?.items[0]
    ) {
      setSelectedRowId(employees.data?.items[0].id);
    }
  }, [selectedRowId, employees.data?.items]);

  const selectedEmployee = useMemo(
    () => employees.data?.items?.find((item) => item.id === selectedRowId),
    [selectedRowId, employees?.data?.items],
  );

  const getRowId = (row: GetEmployeesItem) => row.id!;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">{t("employees.title")}</Typography>

        <Stack direction="row" spacing={1.5}>
          <SearchInput
            onClear={handleSearchClear}
            onButtonClick={handleSearchButtonClick}
            onSubmit={handleSearchSubmit}
            defaultValue={searchValue}
          />

          <Button onClick={handleAddEmployeeClick} endIcon={<IconAdd />}>
            {t("employees.add_employee.button_text")}
          </Button>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <BaseTableWrapper sx={{ height: 560, flex: 1 }}>
          <BaseTable
            columns={columns}
            data={employees.data?.items || []}
            sortingState={sortingState}
            onSortingChange={handleSortingChange}
            sortingOptions={sortingOptions}
            filters={filtersConfig}
            filterState={filterState}
            onFilterChange={handleFilterChange}
            onFiltersReset={handleFiltersReset}
            isDataLoading={employees.isLoading}
            selectedRowId={selectedRowId}
            onRowSelect={handleRowSelect}
            getRowId={getRowId}
            deletedColumnId="archived"
            onFilterDropdownOpen={handleFilterDropdownOpen}
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: employees.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>

        <Card sx={{ width: 480, height: 560 }}>
          <EmployeeDetails
            onDeleteClick={handleDeleteEmployeeClick}
            onEditClick={handleEditEmployeeClick}
            employee={selectedEmployee}
          />
        </Card>
      </Stack>

      <LoadingOverlay open={employees.isLoading} />

      <CreateEmployeeDialog
        isOpen={createEmployeeDialog.value}
        onClose={handleCreateEmployeeClose}
      />
      <UpdateEmployeeDialog
        employee={selectedEmployee}
        isOpen={updateEmployeeDialog.value}
        onClose={updateEmployeeDialog.off}
      />
      <DeleteEmployeeDialog
        employee={selectedEmployee}
        isOpen={deleteEmployeeDialog.value}
        onClose={deleteEmployeeDialog.off}
        onSuccess={handleDeleteEmployeeSuccess}
      />
    </>
  );
};
