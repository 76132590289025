import { getOrdersPath } from "entities/orders";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GetTradeOutletsItem } from "shared/api";
import { analytics, getS3ImageSrc } from "shared/lib";
import {
  ActionButton,
  Box,
  Button,
  IconBox1,
  IconChevronRight,
  IconLocation,
  Logo,
  Stack,
  Typography,
} from "shared/ui";

type EmployeeDetailsProps = {
  onDeleteClick: () => void;
  onEditClick: () => void;
  tradeOutlet?: GetTradeOutletsItem;
};

export const TradeOutletDetails = ({
  tradeOutlet,
  onDeleteClick,
  onEditClick,
}: EmployeeDetailsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  if (!tradeOutlet) return null;

  const handleAllOrdersClick = () => {
    analytics.logAction("ClickStoreAllOrders");

    navigate(getOrdersPath({ tradeOutletIdIn: tradeOutlet.id }));
  };

  const logoSrc = getS3ImageSrc(tradeOutlet.logoUrl);

  return (
    <Stack height="100%">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        p={2.5}
        sx={{ borderBottom: "1px solid", borderColor: "neutral.95" }}
      >
        <Logo src={logoSrc} size="s" />
        <Typography color="neutral.20" variant="bigTextSemiBold">
          {tradeOutlet.name}{" "}
          {tradeOutlet.archived ? t("common.table.deleted_record_label") : ""}
        </Typography>
      </Stack>

      <Box
        p={2.5}
        pb={5.25}
        sx={{
          borderTop: "1px solid",
          borderColor: "neutral.95",
          overflowY: "auto",
        }}
      >
        <Typography variant="smallTextMedium">
          {t("trade_outlets.details.location.label")}
        </Typography>
        <Stack direction="row" spacing={1.5} mt={2} alignItems="center">
          <IconLocation sx={{ color: "neutral.70" }} />
          <Typography variant="smallTextRegular">
            {tradeOutlet.location}
          </Typography>
        </Stack>

        <Typography variant="smallTextMedium" mt={2.5}>
          {t("trade_outlets.details.logo.label")}
        </Typography>
        <Box mt={2}>
          <Logo src={logoSrc} />
        </Box>

        <Typography mt={2.5} mb={2} variant="smallTextMedium">
          {t("trade_outlets.details.orders.label")}
        </Typography>

        <ActionButton
          onClick={handleAllOrdersClick}
          color="gray"
          text={t("trade_outlets.details.all_orders_button_text")}
          startIcon={<IconBox1 />}
          endIcon={<IconChevronRight />}
        />
      </Box>

      <Stack
        direction="row"
        spacing={1}
        p={2.5}
        width="100%"
        marginTop="auto"
        // @TODO Add to ui-web after appearing in DS
        boxShadow="-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04);"
      >
        {tradeOutlet.archived ? (
          <Button disabled fullWidth>
            {t("trade_outlets.details.disabled_button_text")}
          </Button>
        ) : (
          <>
            <Button onClick={onDeleteClick} variant="secondary" fullWidth>
              {t("trade_outlets.details.delete_button_text")}
            </Button>
            <Button onClick={onEditClick} variant="primary" fullWidth>
              {t("trade_outlets.details.change_button_text")}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};
