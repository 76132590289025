import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useQuery } from "@tanstack/react-query";
import { getOrdersChartData } from "shared/api";

import { QUERY_KEY_ORDERS_CHART_DATA } from "../../lib";

export const useOrdersChartData = (
  options: bnplMerchants.GetApiMerchantsServiceOrdersOverviewChartParams,
) =>
  useQuery({
    queryFn: () => getOrdersChartData(options),
    queryKey: [QUERY_KEY_ORDERS_CHART_DATA, options],
  });
