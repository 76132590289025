// @todo add to ui-web after appearing in DS
import { useTheme } from "@mychili/ui-web";
import { Switch, SwitchProps } from "shared/ui";

export const BaseSwitch = (props: SwitchProps) => {
  const { palette } = useTheme();

  return (
    <Switch
      disableRipple
      {...props}
      sx={{
        width: 44,
        height: 24,
        padding: 0,
        "& .MuiSwitch-switchBase": {
          padding: 0,
          margin: "2px",
          "&.Mui-checked": {
            transform: "translateX(20px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor: palette.primary["40"],
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "none",
          boxSizing: "border-box",
        },
        "& .MuiSwitch-track": {
          borderRadius: 26 / 2,
          backgroundColor: palette.neutral["70"],
          opacity: 1,
        },
        "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-thumb":
          {
            backgroundColor: palette.neutral["100"],
          },
        "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track":
          {
            backgroundColor: palette.neutral["90"],
            opacity: 1,
          },
        "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track":
          {
            backgroundColor: palette.primary["60"],
          },

        "&.MuiSwitch-root:hover .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track":
          {
            backgroundColor: palette.primary["30"],
          },
        "&.MuiSwitch-root:hover .MuiSwitch-switchBase:not(.Mui-checked):not(.Mui-disabled) + .MuiSwitch-track":
          {
            backgroundColor: palette.neutral["60"],
          },
      }}
    />
  );
};
