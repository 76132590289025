import { Dot as RechartsDot, DotProps as RechartsDotProps } from "recharts";

type DotProps = RechartsDotProps & {
  isSinglePoint: boolean;
  index?: number;
};

export const Dot = ({ isSinglePoint, index, ...dotProps }: DotProps) => {
  if (isSinglePoint && index !== 1) return null;

  return <RechartsDot {...dotProps} />;
};
