import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_TRADE_OUTLETS } from "entities/trade-outlets";
import { deleteTradeOutlet } from "shared/api";

export const useDeleteTradeOutlet = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTradeOutlet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TRADE_OUTLETS] });
    },
  });
};
