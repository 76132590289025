import { yupResolver } from "@hookform/resolvers/yup";
import { useBoolean } from "@mychili/ui-web";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { config, getS3ImageSrc } from "shared/lib";
import {
  BaseDialog,
  Box,
  Button,
  IconStore,
  InfoCard,
  Input,
  LogoUpload,
  Stack,
  Typography,
} from "shared/ui";

import { merchantSchema } from "../lib";

type MerchantFormData = {
  brandName: string;
  logoUrl?: string | null;
};

export type MerchantFormDataWithLogo = MerchantFormData & {
  logo: Blob | null | undefined;
};

export type UpdateMechantProps = {
  initialData: MerchantFormData;
  onSubmit: (data: MerchantFormDataWithLogo) => void;
  onClose: () => void;
  isOpen: boolean;
};

export const UpdateMerchant = ({
  initialData,
  onSubmit,
  isOpen,
  onClose,
}: UpdateMechantProps) => {
  const { t } = useTranslation();

  const [logoBlob, setLogoBlob] = useState<Blob | null>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MerchantFormData>({
    resolver: yupResolver(merchantSchema),
    reValidateMode: "onSubmit",
    defaultValues: initialData,
  });

  const handleFormSubmit = (data: MerchantFormData) => {
    onSubmit({
      ...data,
      logo: logoBlob,
    });
  };

  const handleLogoUploadComplete = (blob: Blob | null) => {
    setLogoBlob(blob);
  };

  const editLimitationWarning = useBoolean(true);

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("merchant_info.update_modal.title")}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack
          spacing={2.5}
          sx={{
            p: 2.5,
            mt: 2.5,
            borderTop: "1px solid",
            borderColor: "neutral.95",
          }}
        >
          {editLimitationWarning.value && (
            <Box
              sx={{
                // @TODO Remove after adding InfoCard s size to DS
                ".MuiTypography-mediumTextMedium": {
                  fontSize: "16px",
                  lineHeight: "24px",
                },
                ".MuiTypography-regularTextRegular": {
                  fontSize: "14px",
                  lineHeight: "21px",
                },
              }}
            >
              <InfoCard
                title={t("merchant_info.update_modal.warning.title")}
                description={`
                  ${t("merchant_info.update_modal.warning.description.0")}
                  ${t("merchant_info.update_modal.warning.description.1")}
                  ${config.project.merchantSupportEmail}`}
                iconVariant="warning"
                variant="gray"
              />
            </Box>
          )}

          <Typography mb={2} variant="smallTextMedium">
            {t("merchant_info.update_modal.field.logo")}
          </Typography>
          <LogoUpload
            onComplete={handleLogoUploadComplete}
            initialLogoSrc={getS3ImageSrc(initialData.logoUrl)}
          />

          <Typography mb={2} variant="smallTextMedium">
            {t("merchant_info.update_modal.field.brand_name")}
          </Typography>
          <Input
            {...register("brandName")}
            error={Boolean(errors.brandName?.message)}
            helperText={errors.brandName?.message}
            color="gray"
            placeholder={t("merchant_info.update_modal.field.brand_name")}
            InputProps={{
              startAdornment: <IconStore />,
              sx: {
                // @TODO Remove after adding small inputs to DS
                ".MuiInputBase-input": {
                  cursor: "pointer",
                  py: 1.5,
                  fontSize: 14,
                  lineHeight: 21,
                },
              },
            }}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          p={2.5}
          // @TODO Add to ui-web after appearing in DS
          boxShadow="-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04);"
          sx={{
            borderTop: "1px solid",
            borderColor: "neutral.95",
          }}
        >
          <Button onClick={onClose} fullWidth variant="secondary">
            {t("common.back")}
          </Button>
          <Button type="submit" fullWidth>
            {t("merchant_info.update_modal.submit_button_text")}
          </Button>
        </Stack>
      </form>
    </BaseDialog>
  );
};
