import { jwtDecode } from "jwt-decode";

import { analytics } from "./analytics";

type JwtDecodedToken = {
  employee_id: string;
  merchant_id: string;
  // @todo use roles dictionary
  roles: string[];
};

export const setAnalyticsSession = (token: string | null) => {
  if (!token) return;

  const { employee_id, merchant_id, roles } = jwtDecode<JwtDecodedToken>(token);

  analytics.setSession({
    user_id: employee_id,
    merchant_id,
    user_role: roles[0],
  });
};
