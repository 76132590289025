import { useTheme } from "@mychili/ui-web";
import { useTranslation } from "react-i18next";
import {
  Cell,
  Label as RechartsLabel,
  LabelProps,
  Legend,
  Pie,
  PieChart as RechartsPieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  TooltipProps,
} from "recharts";
import { ContentType } from "recharts/types/component/DefaultLegendContent";
import { PolarViewBox } from "recharts/types/util/types";
import { OrdersCountByStatusesItem } from "shared/api";
import { toTitleCase } from "shared/lib";
import { Box, Stack, Typography } from "shared/ui";

import { getChartData, getOrdersTotalCount, OrderPublicStatus } from "../lib";
import { LegendContentProps } from "../types";

type OrdersCountChartProps = {
  data: OrdersCountByStatusesItem[];
};

export const OrdersCountChart = ({ data }: OrdersCountChartProps) => {
  const { palette } = useTheme();

  const totalOrdersCount = getOrdersTotalCount(data || []);

  const chartData = getChartData(data);

  const COLORS_BY_PUBLIC_STATUSES: Record<OrderPublicStatus, string> = {
    CANCELLED: palette.error["70"],
    COMPLETED: palette.success["70"],
    DRAFT: palette.neutral["90"],
    REFUNDED: palette.warning["60"],
    REFUND_IN_PROGRESS: palette.warning["20"],
  };

  const Tooltip = ({ active, payload }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <Stack
          sx={{ backgroundColor: "neutral.20", borderRadius: 0.5 }}
          p={1}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Box
            width={8}
            height={8}
            sx={{
              backgroundColor:
                COLORS_BY_PUBLIC_STATUSES[payload[0].name as OrderPublicStatus],
              borderRadius: "50%",
            }}
          />
          <Typography
            variant="smallTextRegular"
            color="common.white"
            display="inline"
          >
            {toTitleCase(payload[0].name || "")}: {payload[0].value}
          </Typography>
        </Stack>
      );
    }

    return null;
  };

  const renderLabel = ({ viewBox }: LabelProps, value: number | string) => {
    const { t } = useTranslation();

    const cx = (viewBox as PolarViewBox)?.cx ?? 0;
    const cy = (viewBox as PolarViewBox)?.cy ?? 0;

    const positioningProps = {
      x: cx,
      y: cy,
      textAnchor: "middle",
    };

    return (
      <text {...positioningProps}>
        <tspan
          x={cx}
          y={cy - 5}
          fontWeight={600}
          fill={palette.neutral["20"]}
          fontSize={17}
        >
          {value}
        </tspan>
        <tspan
          x={cx}
          y={cy + 15}
          fontSize={14}
          fill={palette.neutral["60"]}
          fontWeight={400}
        >
          {t("overview.orders_count_chart.label")}
        </tspan>
      </text>
    );
  };

  const renderLegendContent = ({ payload }: LegendContentProps) => {
    return (
      <Stack spacing={1}>
        {payload?.map((entry) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              key={entry.payload?.status}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  sx={{
                    width: 11,
                    height: 11,
                    borderRadius: "50%",
                    backgroundColor:
                      COLORS_BY_PUBLIC_STATUSES[
                        entry.payload?.status as OrderPublicStatus
                      ],
                  }}
                />

                <Typography variant="smallTextRegular" color="neutral.50">
                  {toTitleCase(entry.payload?.status)}
                </Typography>
              </Stack>

              <Typography variant="smallTextMedium" color="neutral.10">
                {entry.payload?.total}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return (
    <>
      <ResponsiveContainer>
        <RechartsPieChart width={255} height={285}>
          {totalOrdersCount === 0 ? (
            <Pie
              data={[{ value: 1 }]}
              fill={palette.neutral["80"]}
              paddingAngle={1}
              dataKey="value"
              innerRadius={50}
              outerRadius={64}
              cornerRadius={4}
              cy={74}
            >
              <RechartsLabel
                position="center"
                content={(props: LabelProps) => renderLabel(props, 0)}
              />
            </Pie>
          ) : null}

          <Pie
            data={chartData || []}
            paddingAngle={1}
            dataKey="total"
            nameKey="status"
            innerRadius={50}
            outerRadius={64}
            cornerRadius={4}
          >
            {chartData?.map((item, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS_BY_PUBLIC_STATUSES[item.status]}
                style={{ outline: "none" }}
              />
            ))}

            <RechartsLabel
              position="center"
              content={(props: LabelProps) =>
                renderLabel(props, totalOrdersCount)
              }
            />
          </Pie>

          <Legend
            layout="vertical"
            iconType="circle"
            iconSize={11}
            content={renderLegendContent as unknown as ContentType}
            width={255}
            wrapperStyle={{ paddingTop: "24px", paddingBottom: "20px" }}
          />
          {totalOrdersCount !== 0 ? (
            <RechartsTooltip content={<Tooltip />} />
          ) : null}
        </RechartsPieChart>
      </ResponsiveContainer>
    </>
  );
};
