import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { getEmployeeOutletsPayload } from "entities/employee-outlets";
import { handleEmployeeMutationError } from "entities/employees";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import {
  EmployeeForm,
  EmployeeFormDataForSubmit,
} from "features/employee-form";
import { useUpdateEmployeeOutlets } from "features/update-employee-outlets";
import { useTranslation } from "react-i18next";
import { EmployeeRole } from "shared/api";
import { parseLocalPhoneNumber, showSnackbarSuccess } from "shared/lib";
import { BaseDialog } from "shared/ui";

import { useCreateEmployee } from "../model";

type CreateEmployeeDialogProps = {
  isOpen: boolean;
  onClose: (data?: { employeeId?: string }) => void;
};

export const CreateEmployeeDialog = ({
  isOpen,
  onClose,
}: CreateEmployeeDialogProps) => {
  const { t } = useTranslation();

  const createEmployee = useCreateEmployee();
  const updateEmployeeOutlets = useUpdateEmployeeOutlets();
  const tradeOutletsOptions = useTradeOutletsOptions();

  const handleSubmit = (formData: EmployeeFormDataForSubmit) => {
    const roles: EmployeeRole[] = [
      bnplMerchants.CreateEmployeeExcludeMerchantIdRole.employee,
    ];

    if (formData.isRefundAllowed) roles.push("refund_accessed_employee");

    createEmployee.mutate(
      {
        payload: {
          name: formData.name,
          email: formData.email,
          phone: parseLocalPhoneNumber(formData.phone),
          role: bnplMerchants.CreateEmployeeExcludeMerchantIdRole.employee,
          roles,
        },
      },
      {
        onError: handleEmployeeMutationError,
        onSuccess: ({ id }) => {
          if (formData.tradeOutletsAccessIds) {
            const { bindIds, detachIds } = getEmployeeOutletsPayload(
              formData.tradeOutletsAccessIds,
            );
            updateEmployeeOutlets.mutate(
              {
                employeeId: id!,
                bindIds,
                detachIds,
              },
              {
                onSuccess: () => onClose({ employeeId: id }),
              },
            );
          }

          showSnackbarSuccess(t("employees.notification.employee_added"));
        },
      },
    );
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("employees.add_employee_modal.title")}
    >
      <EmployeeForm
        onClose={onClose}
        tradeOutletsOptions={tradeOutletsOptions.data || []}
        onSubmit={handleSubmit}
      />
    </BaseDialog>
  );
};
