import { IconChevronDown, IconFilterOn } from "@mychili/ui-web";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  DatePicker,
  FilterItem,
  FilterType,
  Input,
  OptionsDropdown,
  Popover,
} from "shared/ui";

type FilterProps = FilterItem & {
  onChange: (
    field: string,
    values: string[] | Date[] | undefined,
    type: FilterItem["type"],
  ) => void;
  value: string[] | Date[] | undefined;
  onDropdownOpen?: (field: string) => void;
};

export const Filter = ({
  onChange,
  field,
  value,
  type = FilterType.Multiple,
  options = [],
  entityLabel,
  onDropdownOpen,
}: FilterProps) => {
  const { t } = useTranslation();

  const selectedEntityLabel = entityLabel ?? t("common.table.filter.selected");

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(e.currentTarget);

    onDropdownOpen?.(field);
  };

  const handleChange = (
    filterValue: string | string[] | Date | Date[] | undefined,
  ) => {
    if (Array.isArray(filterValue) || filterValue === undefined)
      onChange(field, filterValue, type);
  };

  const handleReset = () => {
    onChange(field, undefined, type);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const handleCalendarOpen = () => {
    onDropdownOpen?.(field);
  };

  if (type === "range")
    return (
      <Box
        width={280}
        sx={{
          // @TODO Move to ui-web
          ".MuiFormLabel-root": {
            margin: 0,
          },
        }}
      >
        <DatePicker
          onChange={handleChange}
          numberOfMonths={2}
          color="gray"
          value={value as Date[]}
          placeholder={t("common.table.filter.date.placeholder")}
          onReset={handleReset}
          onCalendarOpen={handleCalendarOpen}
        />
      </Box>
    );

  return (
    <>
      <Input
        color="gray"
        variant="filled"
        onClick={handleInputClick}
        size="small"
        InputProps={{
          endAdornment: value ? (
            <IconFilterOn />
          ) : (
            <IconChevronDown sx={{ cursor: "pointer" }} />
          ),
          readOnly: true,
          sx: {
            // @TODO Remove after adding small inputs to DS
            ".MuiInputBase-input": {
              cursor: "pointer",
              py: 1.5,
              fontSize: 14,
              lineHeight: 21,
            },
          },
        }}
        placeholder={`${t("common.table.filter.placeholder")} ${selectedEntityLabel}`}
        value={value ? `${selectedEntityLabel} (${value.length})` : ""}
        sx={{
          width: 174,
        }}
      />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handlePopoverClose}
        open={Boolean(anchorEl)}
      >
        <OptionsDropdown
          type="check"
          title={t("common.table.filter.label")}
          options={options}
          value={(value || []) as string[]}
          onChange={handleChange}
          showSearch={true}
        />
      </Popover>
    </>
  );
};
