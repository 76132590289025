import { formatDate } from "shared/lib";

export const getPeriodDisplayValue = (
  dates: [Date, Date] | undefined,
): string => {
  if (!dates) return "";

  const withinOneYear = dates[0].getFullYear() === dates[1].getFullYear();
  const withinOneMonth = dates[0].getMonth() === dates[1].getMonth();

  if (withinOneYear) {
    const year = dates[0].getFullYear();

    if (withinOneMonth)
      return `${formatDate(dates[0], "D")} — ${formatDate(dates[1], "D")} ${formatDate(dates[0], "MMMM")} ${year}`;

    return `${formatDate(dates[0], "D MMMM")} — ${formatDate(dates[1], "D MMMM")}, ${year}`;
  }

  return `${formatDate(dates[0], "D MMMM, YYYY")} — ${formatDate(dates[1], "D MMMM, YYYY")}`;
};
