import { formatDate } from "shared/lib";

import { DatePickerValue } from "./types";

export const getDisplayValue = (value: DatePickerValue) => {
  if (!value) return "";

  if (Array.isArray(value) && value.length === 2) {
    if (value[0].getTime() === value[1].getTime())
      return formatDate(value[0], "DD.MM.YYYY");
    return `${formatDate(value[0], "DD.MM.YYYY")} – ${formatDate(value[1], "DD.MM.YYYY")}`;
  }
};

export const formatShortWeekday = (_: string | undefined, date: Date) =>
  formatDate(date, "dd");

export const formatMonth = (_: string | undefined, date: Date) =>
  formatDate(date, "MMM");
