import { useAgreements } from "entities/agreements";
import {
  TradeOutletForm,
  TradeOutletFormDataWithLogo,
} from "features/trade-outlet-form";
import { useUploadTradeOutletLogo } from "features/upload-trade-outlet-logo";
import { useTranslation } from "react-i18next";
import { showSnackbarError, showSnackbarSuccess } from "shared/lib";
import { BaseDialog } from "shared/ui";

import { useCreateTradeOutlet } from "../model";

type CreateTradeOutletDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (tradeOutletId: string) => void;
};

export const CreateTradeOutletDialog = ({
  isOpen,
  onClose,
  onSuccess,
}: CreateTradeOutletDialogProps) => {
  const { t } = useTranslation();

  const createTradeOutlet = useCreateTradeOutlet();
  const uploadTradeOutletLogo = useUploadTradeOutletLogo();
  const agreements = useAgreements();

  const handleSubmit = (data: TradeOutletFormDataWithLogo) => {
    if (!agreements.data?.[0]) {
      showSnackbarError(t("trade_outlets.notification.no_agreement_error"));
      return;
    }

    createTradeOutlet.mutate(
      {
        payload: {
          agreement_id: agreements.data?.[0].id!,
          name: data.name,
          location: data.location,
        },
      },
      {
        onError: () => {
          showSnackbarError(
            t("trade_outlets.notification.create_trade_outlet_error"),
          );
        },
        onSuccess: (response) => {
          if (!response.id) return;

          onSuccess(response.id);

          if (data.logo) {
            uploadTradeOutletLogo.mutate(
              {
                tradeOutletId: response.id,
                imageBlob: data.logo,
              },
              {
                onError: () => {
                  showSnackbarError(
                    t("trade_outlets.notification.upload_logo_error"),
                  );
                },
              },
            );
          }

          showSnackbarSuccess(
            t("trade_outlets.notification.create_trade_outlet_success"),
          );

          onClose();
        },
      },
    );
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("trade_outlets.add_trade_outlet_modal.title")}
    >
      <TradeOutletForm onClose={onClose} onSubmit={handleSubmit} />
    </BaseDialog>
  );
};
