import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
} from "@mychili/ui-web";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import { PaginationState } from "../types";

type BaseTablePaginationProps = {
  state: PaginationState;
  pageSizeOptions?: number[];
  bottomLabel?: string;
};

export const BaseTablePagination = ({
  state,
  pageSizeOptions = [20, 50],
  bottomLabel,
}: BaseTablePaginationProps) => {
  const { t } = useTranslation();

  const {
    currentPage,
    totalCount,
    handleCurrentPageChange,
    handlePageSizeUpdate,
    currentLimit,
  } = state;

  const pagesCount = totalCount ? Math.ceil(totalCount / currentLimit) : 0;

  const firstPage = currentPage * currentLimit + 1;
  const lastPage = Math.min(firstPage + currentLimit - 1, totalCount ?? 0);

  const showBottomLabel = Boolean(bottomLabel);

  const handlePageSizeChange = (e: SelectChangeEvent<unknown>) => {
    handlePageSizeUpdate(Number(e.target.value));
  };

  return (
    <Stack
      px={2.5}
      py={1.5}
      direction="row"
      justifyContent={showBottomLabel ? "flex-start" : "space-between"}
      alignItems="center"
      gap={2.5}
      sx={{
        backgroundColor: "common.white",
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderTop: "1px solid",
        borderColor: "neutral.95",
        marginTop: "auto",
        boxShadow:
          // @TODO Add to ui-web after appearing in DS
          "-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04)",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="smallTextRegular">
          {t("common.table.pagination.label")}
        </Typography>
        <Select
          color="gray"
          IconComponent={IconChevronDown}
          variant="filled"
          onChange={handlePageSizeChange}
          sx={{
            width: "68px",
            // @TODO Remove after adding small inputs to ui-kite
            ".MuiInputBase-input.MuiSelect-select": {
              padding: "10px",
            },
          }}
          value={currentLimit}
        >
          {pageSizeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Typography variant="smallTextRegular" minWidth={95}>
        {t("common.table.pagination.value", {
          current: `${firstPage}-${lastPage}`,
          total: totalCount,
        })}
      </Typography>

      <Stack direction="row" spacing={1}>
        <IconButton
          color="gray"
          disabled={currentPage === 0}
          onClick={() => handleCurrentPageChange(currentPage - 1)}
        >
          <IconChevronLeft />
        </IconButton>
        <IconButton
          color="gray"
          disabled={currentPage === pagesCount - 1 || totalCount === 0}
          onClick={() => handleCurrentPageChange(currentPage + 1)}
        >
          <IconChevronRight />
        </IconButton>
      </Stack>
      <Show when={showBottomLabel}>
        <Stack alignItems="flex-end" flex={1}>
          <Typography variant="mediumTextMedium" fontWeight={500}>
            {bottomLabel}
          </Typography>
        </Stack>
      </Show>
    </Stack>
  );
};
